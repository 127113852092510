.gray-bg {
  background: $px-gray;
}

.theme-bg {
  background: $px-theme;
}

.dark-bg {
  background: $px-dark;
}

.color-dark {
  color: $px-dark !important;
}
.color-white {
  color: $px-white;
}

.color-theme {
  color: $px-theme;
}
